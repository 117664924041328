import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div>
    <a href="https://www.predinfer.com/"><img src="/img/404.png" alt="This is a 404 error, which means you've clicked on a bad link or entered an invalid URL."/></a>
    </div>
  </Layout>
);

export default NotFoundPage;
